:root {
    --colorFontLight: #a9bcbd;
    --beehive-bg: #313131;
    --beehive-dark: #202124;
    --beehive-hover-dark: #343a40;
    --beehive-font-color: #95a5a6;
    --beehive-grey: #dee2e6;
}

body {
    /* background-color: #1e1e1e; */
}

nav {
    font-size: 12px;
    z-index: 10;
}

@media (min-width: 768px){
    .navbar {
        padding-top: 0px !important;
    }
}

.bg-over {
    top: 0;
    left: 0;
    right: 0;
    height: 240px;
    background: linear-gradient(to right, #000000 0%, #130d00 100%);
    background-size: cover;
    z-index: -1;
    position: absolute;
    pointer-events: none;
}

.nav-tabs {
    border: none;
    width: 100%;
    border-bottom: 2px solid var(--beehive-bg-dark);
    color: var(--beehive-font-color);
    cursor: pointer;
}
.nav-tabs .nav-link {
    border: none !important;
}

.nav-item a.active {
    background-color: #202124 !important;
    border: #202124;
    color: #FFF !important;
}

.shadow {
    box-shadow: 0 0 6px rgba(0,0,0,0.3), 0 0 10px rgba(0,0,0,0.2);
}

.color_logo {
    color:#d39e00 !important;
}

.btns_files button {
    border: none;
    margin: 0 5px 0px 0;
    padding: 8px 24px 8px 8px;
    border-radius: 0 4px !important;
    color: #b4bcc2
}

.btns_files .icon_ext {
    margin-right: 8px;
}

.btns_files .active {
    background-color: #202124 !important;
    color: #FFF !important;
}

.tabs svg {
    margin-right: 12px;
}





.jumbotron, .bg-beehive {
    background-color: var(--beehive-bg) !important;
    color: #95a5a6 !important;
}

.bg-beehive-dark, .breadcrumb {
    background-color: #202124 !important
}

button.bg-beehive {
    border: none !important;
}

.mb--1 {
    margin-bottom: -4px;
}

.list-group-item.disabled {
    background-color: #252526 !important;
    color: #95a5a6 !important
}

.list-group-item.active {
    background-color: #202124 !important;
    border: none !important;
    color:#dee2e6 !important;
}
.list-group-item-action:hover {
    background-color: var(--beehive-hover-dark) !important
}


.list-group-item-action {
    background-color: #202124 !important
}


.btn-primary {
    background-color: #252526 !important;
    border: none;
}

.srd-diagram {
    min-height: 80vh !important;
    height: 100% !important;
} 





.shadow {
  position: relative;
}
.shadow:before, .effect2:after
{
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width:300px;
  background: #777;
  -webkit-box-shadow: 0 15px 10px #777;
  -moz-box-shadow: 0 15px 10px #777;
  box-shadow: 0 15px 10px #777;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
.shadow:after
{
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 10px;
  left: auto;
}

.buttons-down {
    padding-top: 16px;
}

.beeview .list-group a svg:first-child {
    margin-right: 8px;
}
.beeview button svg:first-child {
    margin-right: 8px;
}

.beeform button {
    margin: 4px 0px 0 8px;
    text-transform: capitalize;
}
.beeform input, textarea, select {

}

.beeform label {
    text-transform: capitalize;
}

.beeform table {
    color: var(--colorFontLight) !important;
    background-color: transparent !important;
    border-radius: 16px;

}

.beeform table td {
    padding: 8px !important;
    border-top: none !important;
}

.beeform table th {
    border-color: #202124 !important;
}

.beeform table tbody tr:hover {
    background-color: var(--beehive-hover-dark) !important;
    cursor: pointer;
}

.beeview .beesection {
    background-color: #252526f5 !important;
}

.beeview .beesection table {
    background-color: #2021248c !important;
}

.beeglobal.beesection {
    background-color: #252526b8 !important;
    border-top: 6px solid #2f2e2e;
}

.beeglobal.beesection button {
    background-color: #252526;
    border-color: #333333;
    padding: 6px 40px 6px 40px;
    color: white;
}

.beemodels>div {
    position: inherit !important;
}

#modules input {
    border-radius: 0px !important;
    border: 1px solid #3a3a3e;
    background-color: var(--beehive-dark);
}

#module_code #console,
#module_playcode #console {
    background-color: #212529;
    border: 1px solid #1e1e1e !important;
    padding: 6px;
    color: #b4bcc2;
    letter-spacing: 1px;
}




/* Effects  */

/* Ripple effect */
.ripple {
    background-position: center;
    transition: background 0.6s;
  }
  .ripple:hover {
    background: #313131 radial-gradient(circle, transparent 1%, #252526 1%) center/15000%;
  }
  .ripple:active {
    background-color: #FFF;
    background-size: 100%;
    transition: background 0s;
  }

  /* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #2e2e2e; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }



  .srd-default-port--out .name {
      display: none !important;
  }



